import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { concatClassNames as cn } from "@system42/core";

import {
  Headline2Sans,
  HighlightedText,
  urlKnowledgeBaseHowToTestInVision,
} from "../../system42";
import { featureOverviewFeatures, advertedPoolSize } from "../../helpers";

import Layout from "../../layouts/layout";

import { ContentCentered } from "../../components/ContentCentered";
import { FeatureOverview } from "../../components/FeatureOverview";
import { GetStartedBox } from "../../components/GetStartedBox";
import { HeaderOverlap } from "../../components/HeaderOverlap";
import { ListCentered } from "../../components/ListCentered";
import { Slider } from "../../components/Slider";
import { TwoColumns } from "../../components/TwoColumns";

import * as helperStyles from "../helpers.module.css";
import * as styles from "./styles.module.css";

import imageCopyLinkInVision from "./images/copyLinkInVision.jpg";
import imageTestInstructions from "./images/testInstructions.jpg";
import imageOrderTesters from "./images/orderTesters.jpg";
import imageResult from "./images/result.jpg";
import imageNeedHelp from "./images/needHelp.jpg";

const ContentPage = () => (
  <Layout
    title={"User Testing for InVision Prototypes"}
    metaDescription={
      "Want to test an InVision prototype on potential users? Userbrain is your go-to remote user testing tool and is perfectly compatible with InVision."
    }
  >
    <article>
      <HeaderOverlap
        isDisplayCta
        title={
          <>
            <span className={helperStyles.h1Serif}>User testing for</span>{" "}
            InVision prototypes
          </>
        }
        titleWidth={"10em"}
        staticImage={(className) => (
          <StaticImage
            className={className}
            src={"./images/userbrain-invision.jpg"}
            alt={"Userbrain and InVision"}
          />
        )}
      />

      <div className={helperStyles.container}>
        <ContentCentered
          className={helperStyles.spacingLg}
          title={"Testing your InVision prototype on real people is easy!"}
          titleWidth={"15em"}
          textContent={
            "Want to test an InVision prototype on potential users? Userbrain is your go-to remote user testing tool and is perfectly compatible with InVision."
          }
        />
      </div>

      <Slider
        className={helperStyles.spacingLg}
        subtitle={"How it works"}
        slides={[
          {
            title: "Create a test and add your public InVision prototype link.",
            imageSrc: imageCopyLinkInVision,
            imageAlt: "Copy public link from InVision prototype",
          },
          {
            title:
              "Set a list of tasks to be carried out by the tester or choose one of our predefined templates",
            imageSrc: imageTestInstructions,
            imageAlt: "Userbrain task and instructions editor",
          },
          {
            title: `Get testers from our ${advertedPoolSize} pool of testers or you can invite your own participants`,
            imageSrc: imageOrderTesters,
            imageAlt:
              "Modal to order remote user testers in the Userbrain dashboard interface",
          },
          {
            title:
              "Watch videos of people interacting with your InVision prototype while thinking aloud",
            imageSrc: imageResult,
            imageAlt: "Userbrain user test player showing a example video",
          },
        ]}
      />

      <div
        className={cn(
          helperStyles.container,
          helperStyles.container_featureOverview
        )}
      >
        <FeatureOverview
          className={helperStyles.spacingLg}
          title={"Best features for easy unmoderated remote user testing."}
          titleWidth={"17em"}
          features={featureOverviewFeatures}
        />
      </div>
      <div className={helperStyles.container}>
        <section className={helperStyles.spacingLg}>
          <Headline2Sans
            className={styles.threeReasonsTitle}
            style={{ maxWidth: "20em" }}
            customTag={"h1"}
          >
            3 reasons why you should perform Userbrain tests on InVision
            prototypes:
          </Headline2Sans>
          <ListCentered
            className={styles.threeReasonsList}
            type={"number"}
            color={"var(--color-secondary-2)"}
            items={[
              {
                title: "Reduce costs",
                text: "Avoid costly mistakes — by testing your InVision prototype early you’ll save time and money.",
              },
              {
                title: "Get an outside perspective",
                text: "Get unbiased feedback from people outside your own four walls.",
              },
              {
                title: "Fail fast",
                text: "Reiterate different versions of your prototype and continuously improve your design.",
              },
            ]}
          />
        </section>
        <TwoColumns
          className={helperStyles.spacingLg}
          heading={"Need help?"}
          content={
            "We have a dedicated section on how to test your InVision prototypes in our knowledge base."
          }
          linkHref={urlKnowledgeBaseHowToTestInVision}
          linkText={"Read more"}
          imgSrc={imageNeedHelp}
          imgAlt={
            "Macbook Pro with knowledge base article about user testing InVision prototypes with Userbrain opened"
          }
        />
        <GetStartedBox
          className={helperStyles.spacingLg}
          title={
            <>
              Ready for your first{" "}
              <HighlightedText cursor>user test?</HighlightedText>
            </>
          }
          textBottom={
            "Get set up in minutes. Your first test is ready in a few hours."
          }
        />
      </div>
    </article>
  </Layout>
);

export default ContentPage;
